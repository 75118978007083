const TestimonialItem = ({ name, title, description, img }) => {
  return (
    <section className="rounded-md w-full min-h-[344px] max-h-[350px] md:min-h-[560px] md:max-h-[550px] bg-white pt-5 pb-7 px-3 md:px-7">
      <div className="flex items-center gap-5 md:gap-7">
        <img
          className="size-24 md:size-36 rounded-full"
          src={img}
          alt="testimonials"
        />
        <div className="flex flex-col ">
          <p className="text-xl md:text-4xl -mb-1 font-semibold  whitespace-nowrap font-secondary">
            {name}
          </p>
          <p className="text-[.8rem] md:text-2xl font-thin text-ellipsis overflow-hidden whitespace-nowrap  font-secondary">
            {title}
          </p>
          <div className="flex gap-2">
            {[1, 2, 3, 4, 5].map((_) => (
              <img
                src={require("assets/star.png")}
                alt="star"
                className="size-4 md:size-6"
              />
            ))}
          </div>
        </div>
      </div>
      <p className="text-sm md:text-2xl mt-5">{description}</p>
    </section>
  );
};

export default TestimonialItem;
