/**
 * This code was generated by Builder.io.
 */
import React from "react";

const VideoSection = () => {
  return (
    <section className="flex overflow-hidden relative flex-col justify-end items-center px-16 py-10 md:py-20 lg:mt-11 w-full text-white md:min-h-[796px] max-md:px-5 md:mt-10 max-md:max-w-full">
      <img
        loading="lazy"
        src={require("assets/video-section-bg.png")}
        alt=""
        className="object-cover absolute inset-0 size-full"
      />
      <div className="flex  gap-5 self-start max-w-full w-fit max-md:flex-wrap pt-10 mt-10  bottom-0 justify-center items-start md:items-center z-10 mx-auto">
        <button className="justify-center items-center text-nowrap py-2 px-2 lg:px-16 lg:py-6 text-xs md:text-3xl font-semibold text-center border border-white border-solid bg-black bg-opacity-30 rounded-[105.907px] max-md:px-5">
          Play Video
        </button>
        <div className="h-32 w-[2px] hidden md:block bg-white"></div>
        <div className="flex-auto text-center md:text-start font-secondary self-end md:self-start -mb-7  text-brand-darkYellow text-3xl md:text-6xl tracking-tighter max-md:max-w-full">
          Florida International University <br /> Youngest Board Member
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
