import { links } from "constants/links";

const SocialLinksBar = () => {
    return (
        <div className="flex flex-col gap-8 h-fit px-6 py-10 rounded-2xl bg-opacity-20 items-center">
            <a href={links.tiktok} className="w-fit" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36" className="hover:fill-black fill-white">
                    <path d="M26.5944 5.48333C25.2429 3.96593 24.4982 2.01723 24.4987 0H18.3897V24.1111C18.3426 25.4159 17.7825 26.6518 16.8273 27.5585C15.8722 28.4652 14.5966 28.972 13.2691 28.9722C10.4617 28.9722 8.12883 26.7167 8.12883 23.9167C8.12883 20.5722 11.4107 18.0639 14.7915 19.0944V12.95C7.97066 12.0556 2 17.2667 2 23.9167C2 30.3917 7.45663 35 13.2494 35C19.4573 35 24.4987 30.0417 24.4987 23.9167V11.6861C26.976 13.4358 29.9501 14.3746 33 14.3694V8.36111C33 8.36111 29.2832 8.53611 26.5944 5.48333Z" />
                </svg>
            </a>
            <a href={links.instagram} className="w-fit" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" className="fill-none hover:invert">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.3274 2.45999H12.131C6.53578 2.45999 2 6.99577 2 12.5909V27.7874C2 33.3824 6.53578 37.9183 12.131 37.9183H27.3274C32.9225 37.9183 37.4583 33.3824 37.4583 27.7874V12.5909C37.4583 6.99577 32.9225 2.45999 27.3274 2.45999Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7394 27.7872C15.543 27.7872 12.1412 24.3854 12.1412 20.1889C12.1412 15.9924 15.543 12.5907 19.7394 12.5907C23.9359 12.5907 27.3377 15.9924 27.3377 20.1889C27.3377 22.204 26.5371 24.1367 25.1121 25.5616C23.6872 26.9866 21.7545 27.7872 19.7394 27.7872Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M29.8559 7.52525C28.4571 7.52525 27.3231 8.6592 27.3231 10.058C27.3231 11.4568 28.4571 12.5907 29.8559 12.5907C31.2547 12.5907 32.3886 11.4568 32.3886 10.058C32.3886 8.6592 31.2547 7.52525 29.8559 7.52525Z" />
                    <path d="M29.8601 8.79185C29.1607 8.79185 28.5938 9.35882 28.5938 10.0582C28.5938 10.7576 29.1607 11.3246 29.8601 11.3246C30.5595 11.3246 31.1265 10.7576 31.1265 10.0582C31.1265 9.35882 30.5595 8.79185 29.8601 8.79185Z" className="hover:fill-black fill-none" stroke="white" stroke-width="2" stroke-linecap="round" />
                </svg>
            </a>
            <a href={links.youtube} className="w-fit" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="33" viewBox="0 0 45 33" className="hover:fill-black fill-white">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8361 22.3216V9.7919C22.2842 11.8849 25.7292 13.9068 29.8036 16.0867C26.4431 17.9504 22.2842 20.0415 17.8361 22.3216ZM42.6252 3.5603C41.8579 2.5494 40.5502 1.76251 39.1579 1.50199C35.0657 0.724888 9.53599 0.722678 5.44599 1.50199C4.32952 1.71129 3.33534 2.21719 2.48131 3.0032C-1.11718 6.34315 0.0104184 24.2542 0.877794 27.1556C1.24253 28.4114 1.71405 29.3171 2.30787 29.9116C3.07294 30.6976 4.12044 31.2388 5.32364 31.4815C8.69306 32.1784 26.0517 32.5681 39.0867 31.5862C40.2877 31.3769 41.3508 30.8182 42.1893 29.9988C45.5164 26.6722 45.2896 7.75527 42.6252 3.5603Z" />
                </svg>
            </a>
            <a href={links.facebook} className="w-fit" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" className="hover:fill-black fill-white">
                    <path d="M18.0664 0.176666C8.12987 0.176666 0 8.28847 0 18.2792C0 27.3124 6.6123 34.81 15.248 36.1649V23.5185H10.6592V18.2792H15.248V14.2865C15.248 9.75185 17.9399 7.25869 22.0771 7.25869C24.0464 7.25869 26.1059 7.60195 26.1059 7.60195V12.0643H23.8296C21.5893 12.0643 20.8847 13.4555 20.8847 14.8827V18.2792H25.9072L25.0942 23.5185H20.8847V36.1649C25.1419 35.4925 29.0186 33.3204 31.8147 30.0404C34.6109 26.7607 36.1425 22.5891 36.1328 18.2792C36.1328 8.28847 28.0029 0.176666 18.0664 0.176666Z" />
                </svg>
            </a>
            <a href={links.linkedin} className="w-fit" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" className="hover:fill-black fill-white">
                    <path d="M32.6187 0.168097H3.03584C2.65454 0.150236 2.27345 0.207741 1.9144 0.337351C1.55535 0.466962 1.22539 0.666108 0.943394 0.923379C0.661415 1.18067 0.432929 1.49106 0.271055 1.83675C0.10918 2.18246 0.0170628 2.55668 0 2.93803V32.5874C0.0223367 33.3854 0.349365 34.1448 0.913921 34.7092C1.47848 35.2738 2.23775 35.6009 3.03584 35.6232H32.6187C33.3945 35.5887 34.126 35.2514 34.6562 34.6839C35.1865 34.1164 35.4733 33.3639 35.4551 32.5874V2.93803C35.4615 2.56686 35.3919 2.19832 35.2508 1.85498C35.1096 1.51167 34.8998 1.20079 34.6341 0.941417C34.3686 0.682063 34.0528 0.479703 33.7063 0.346725C33.3597 0.213746 32.9896 0.152962 32.6187 0.168097ZM11.0797 29.7288H5.91657V13.9292H11.0797V29.7288ZM8.62002 11.5359C8.25973 11.5484 7.90072 11.4864 7.56558 11.3535C7.23042 11.2207 6.92635 11.02 6.67249 10.764C6.41861 10.5081 6.2204 10.2024 6.09032 9.86617C5.96022 9.52995 5.90108 9.17045 5.91657 8.81027C5.90064 8.44619 5.96098 8.08282 6.09373 7.74345C6.22649 7.40406 6.42872 7.09618 6.68743 6.83955C6.94616 6.5829 7.25566 6.38317 7.5961 6.25319C7.93655 6.12318 8.30041 6.06578 8.66434 6.08466C9.02463 6.07212 9.38363 6.13419 9.71877 6.26701C10.0539 6.39984 10.358 6.60054 10.6119 6.8565C10.8657 7.11246 11.064 7.41815 11.194 7.75438C11.3241 8.0906 11.3833 8.4501 11.3678 8.81027C11.3837 9.17435 11.3234 9.53772 11.1906 9.8771C11.0579 10.2165 10.8556 10.5244 10.5969 10.781C10.3382 11.0377 10.0287 11.2374 9.68826 11.3674C9.3478 11.4974 8.98394 11.5548 8.62002 11.5359ZM29.5607 29.7288H24.3754V21.0867C24.3754 19.0258 23.6441 17.6076 21.8049 17.6076C21.2336 17.6125 20.6776 17.794 20.2136 18.1273C19.7494 18.4605 19.3997 18.9292 19.2122 19.469C19.0682 19.8737 19.0079 20.3033 19.035 20.7321V29.7288H13.9383V13.9292H19.035V16.1451C19.4881 15.3126 20.1624 14.6217 20.9837 14.1483C21.8049 13.6752 22.7409 13.4383 23.6884 13.4638C27.0345 13.4638 29.5607 15.6798 29.5607 20.3997V29.7288Z" />
                </svg>
            </a>
        </div>
    );
}

export default SocialLinksBar;