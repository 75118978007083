/**
 * This code was generated by Builder.io.
 */
import React from "react";
import ServiceCard from "./ServiceCard";

const servicesData = [
  {
    title: "Financial Consulting",
    description: "Empowering your financial future.",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c2cc7fc4e96d68ae2855eca7e731748324ba0e84de6dede7fca0049f8dfb4252?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2df19758e516a6dd2355f9844b70e812f192e182de95aee35e1837cdb86acb60?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    link: "/services/financial-consulting",
  },
  {
    title: "Capital Procurement",
    description: "Fueling your business growth.",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2271e13fe4dfca0c15d1d8f1e6346a05a1742a8544909922ef0bc194ecdbcc0c?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/f1d3b52da264b02c09d2825748bf5c1dc49e2747a9ce1393c340100094ece951?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    link: "/services/capital-procurement",
  },
  {
    title: "Risk Management",
    description: "Protecting your business future.",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ee8b34138568a412589f5ef421ff172ebaaabc0f396adc3da344da6a01248617?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/55903f00eb1ccbff78542939aac112591d52c23ed6045ac947275bb3e6ec671e?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    link: "/services/risk-management",
  },
  {
    title: "Sports & Entertainment",
    description: "Bridging talent & opportunity.",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c2cc7fc4e96d68ae2855eca7e731748324ba0e84de6dede7fca0049f8dfb4252?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/284d80b89b1ae87c326bd0907272ae0f934b4aac60282142cdc06b235bf1c9a8?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    link: "/services/sports-and-entertainment",
  },
  {
    title: "Community & Mentorship",
    description: "Fostering connections & growth.",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2271e13fe4dfca0c15d1d8f1e6346a05a1742a8544909922ef0bc194ecdbcc0c?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/16afd5085dd9222c500f5a9456a3421d80f792c5670d97daa4ebc61dfdfd5e4a?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    link: "/services/community-and-mentorship",
  },
  {
    title: "Serving Others",
    description: "Giving back to the community",
    iconSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ee8b34138568a412589f5ef421ff172ebaaabc0f396adc3da344da6a01248617?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/72086b9d7af94679d6b0efde9e65eadf1f47d0c6c67ba6c39b852102e9924e7c?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&",
    link: "/services/serving-others",
  },
];

const Services = () => {
  return (
    <section
      className="self-center px-1 mt-16 w-auto max-w-[1800px] max-md:mt-10 max-md:max-w-full"
      id="services"
    >
      <h2 className="self-center text-xl px-2  lg:text-6xl font-semibold text-center text-brand-darkYellow font-secondary uppercase leading-[49.8px] max-md:mt-5 max-md:max-w-full max-md:text-4xl">
        LEARN MORE ABOUT OUR SERVICES
      </h2>
      <div className="w-[90%] mx-auto bg-black h-[1px] lg:opacity-20 mt-5 lg:mt-10" />
      <div className="flex flex-wrap gap-x-5 gap-y-5 lg:gap-y-10 md:mt-32 mt-5  justify-center">
        {servicesData.map((service, index) => (
          <div key={index} className="w-[43%] lg:w-[30%]">
            <ServiceCard {...service} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
