import React from "react";

const InfoBlock = ({ title, description, img }) => {
  const formattedDescription = description.split("\n").map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));
  return (
    <section className="flex flex-col md:flex-row gap-4 items-center md:gap-11 w-full col-span-full ">
      <div className="flex flex-col lg:w-1/2  gap-7 text-black">
        <h1 className="text-[8.5vw] leading-[0.7]  font-secondary font-medium">
          {title}
        </h1>
        <p className="font-normal hidden md:block   text-[1.4vw]">
          {formattedDescription}
        </p>
      </div>
      <div className="lg:w-1/2">
        <img
          src={img}
          alt="Dimitry Prophete"
          width={"auto"}
          height={"auto"}
          className="w-auto h-auto object-contain"
        />
        <p className="font-normal block md:hidden  mt-4 text-xs lg:text-[1.4vw]">
          {formattedDescription}
        </p>
      </div>
    </section>
  );
};

export default InfoBlock;
