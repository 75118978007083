const Connect = () => {
  return (
    <section className="bg-brand-darkGrey mt-14 md:mt-0 justify-evenly items-center flex flex-col md:items-start md:flex-row md:px-[5%] w-auto lg:max-h-[670px] gap-5 md:gap-10">
      <img
        src={require("assets/about/dimitry-connect.png")}
        alt="Dimitry Prophete"
        className="h-full  hidden md:block w-1/3 md:w-auto -mt-20"
      />
      <img
        src={require("assets/about/dimitry-connect-mobile.png")}
        alt="Dimitry Prophete"
        className="h-full block md:hidden  w-1/3 md:w-auto -mt-20"
      />
      <div className="text-white px-[10%] md:px-0 text-center  md:text-start md:py-[5%] flex flex-col">
        <h2 className=" text-xl font-medium  md:text-5xl">
          "Now, I want to give back by mentoring the next generation.”
        </h2>
        <p className="text-md md:text-2xl font-light mt-2 md:mt-10 ">
          If you're eager to learn, grow, & make impactful connections, <br />{" "}
          let's connect. Click below to start your journey with me.
        </p>
        <div className="bg-brand-darkYellow h-[1px] my-4 md:my-10"></div>
        <div></div>
        <button className="place-self-start  mx-auto md:mx-0 text-nowrap px-4 py-2 md:py-4 md:px-14 text-md  md:text-3xl font-semibold md:max-w-96 text-center border border-white border-solid bg-transparent bg-opacity-30 rounded-[105.907px] mb-5">
          Let's Connect
        </button>
      </div>
    </section>
  );
};

export default Connect;
