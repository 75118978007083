import Contact from "components/home/Contact";
import Footer from "components/home/Footer";
import Header from "components/home/Header";
import Hero from "components/home/Hero";
import LocationSection from "components/home/LocationSection";
import Relationships from "components/home/Relationships";
import Services from "components/home/Services";
import Testimonial from "components/home/Testimonial";
import TrustedBrands from "components/home/TrustedBrands";
import VideoSection from "components/home/VideoSection";
import { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    document.title = "Home";
  }, []);
  return (
    <main className="flex flex-col bg-stone-50 overflow-clip">
      <div className="bg-[url('assets/hero-bg.png')] bg-[top_right_-20rem] md:bg-top   bg-cover bg-no-repeat w-full  h-[90vh] lg:h-screen relative flex flex-col justify-end lg:justify-normal">
        <Header hasInitialTransparentBgOpacity />
        <div className="h-full w-full absolute bg-black opacity-25 md:hidden block"></div>
        <Hero />
      </div>
      <Services />
      <Relationships />
      <TrustedBrands />
      <VideoSection />
      <Testimonial />
      <Contact
        actionBtnText={"Schedule a meeting"}
        bodyText={
          "We aim to foster local tech talent, startups & small businesses through accelerators, relations, & networking opportunities."
        }
      />
      <LocationSection />
      <Footer />
    </main>
  );
};

export default HomePage;
