import Biography from "components/About/Biography";
import Connect from "components/About/Connect";
import Welcome from "components/About/Welcome";
import Footer from "components/home/Footer";
import Header from "components/home/Header";
import { useEffect } from "react";

const AboutPage = () => {
    useEffect(() => {
        document.title = 'Home';
    }, []);
    return (
        <main className="flex flex-col bg-stone-50 ">
            <Header hasInitialTransparentBgOpacity={false} />
            <Welcome />
            <Biography />
            <Connect />
            <Footer />
        </main>
    )
}

export default AboutPage;