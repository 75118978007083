/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Relationships = () => {
  return (
    <section className="flex overflow-hidden bg-brand-darkGrey font-secondary relative flex-col justify-center items-center px-16 md:py-20 lg:py-20 mt-20 w-full text-5xl font-medium tracking-tighter leading-8 text-center text-white uppercase whitespace-nowrap  h-12  max-md:px-5 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
      <div className="flex relative gap-2 md:gap-5 items-center w-full max-w-[1590px] flex-wrap max-md:max-w-full max-md:text-4xl">
        <div className="grow self-stretch my-auto text-xs md:text-4xl">
          Relationships
        </div>
        <img
          loading="lazy"
          src={require("assets/star.png")}
          alt=""
          className="shrink-0 self-center md:self-start aspect-square w-[7px] md:w-[33px]"
        />
        <div className="flex-auto self-stretch my-auto text-xs md:text-4xl">
          connect
        </div>
        <img
          loading="lazy"
          src={require("assets/star.png")}
          alt=""
          className="shrink-0 self-center md:self-start aspect-square w-[7px] md:w-[33px]"
        />
        <div className="flex-auto self-stretch my-auto text-xs md:text-4xl">
          network
        </div>
        <img
          loading="lazy"
          src={require("assets/star.png")}
          alt=""
          className="shrink-0 self-center md:self-start aspect-square w-[7px] md:w-[33px]"
        />
        <div className="flex-auto self-stretch my-auto text-xs md:text-4xl">
          partnerships
        </div>
      </div>
    </section>
  );
};

export default Relationships;
