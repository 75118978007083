import { links } from "constants/links";
import AboutPage from "pages/About";
import CapitalProcurement from "pages/CapitalProcurement";
import CommunityAndMentorship from "pages/CommunityAndMentorship";
import FinancialConsulting from "pages/FinancialConsulting";
import HomePage from "pages/HomePage";
import RiskManagement from "pages/RiskManagement";
import ServingOthers from "pages/ServingOthers";
import SportsAndEntertainment from "pages/SportsAndEntertainment";
import { Routes, Route } from "react-router-dom";

const RouteList = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path={links.servicePages.financialConsulting} element={<FinancialConsulting />} />
      <Route path={links.servicePages.capitalProcurement} element={<CapitalProcurement />} />
      <Route path={links.servicePages.riskManagement} element={<RiskManagement />} />
      <Route path={links.servicePages.sportsAndEntertainment} element={<SportsAndEntertainment />} />
      <Route path={links.servicePages.communityAndMentorship} element={<CommunityAndMentorship />} />
      <Route path={links.servicePages.servingOthers} element={<ServingOthers />} />

    </Routes>
  );
};

export default RouteList;
