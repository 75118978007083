import Menu from "components/Menu";
import { links } from "constants/links";
import React, { useState, useEffect } from "react";
import { useRef } from "react";

const Header = ({ hasInitialTransparentBgOpacity = false }) => {
  const [bgOpacity, setBgOpacity] = useState(
    hasInitialTransparentBgOpacity ? "bg-opacity-30" : "bg-opacity-100"
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (hasInitialTransparentBgOpacity) {
      const handleScroll = () => {
        const heroHeight = document.getElementById("hero").offsetHeight;
        if (heroHeight && window.scrollY > heroHeight) {
          setBgOpacity("bg-opacity-100");
        } else {
          setBgOpacity("bg-opacity-30");
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasInitialTransparentBgOpacity]);

  return (
    <>
      <header
        className={`flex justify-center  items-center px-[10%] pb-4 md:py-4 w-screen text-lg bg-black ${bgOpacity} max-md:px-5 max-md:max-w-full text-white fixed top-0 z-50`}
      >
        <div className="flex relative gap-5 items-center w-full max-w-[1598px] max-md:flex-wrap max-md:max-w-full">
          <div ref={dropdownRef} className="w-[300px] hidden md:block">
            <div
              tabIndex={0}
              role="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className=" hover:bg-opacity-40 bg-transparent text-white justify-between flex flex-row items-center self-stretch py-1 px-7 whitespace-nowrap border border-white border-solid rounded-[90px] max-md:px-5 sm:min-w-[300px] max-h-[50px] my-auto"
            >
              Discover
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                className={`${
                  showDropdown ? "-scale-y-100" : "scale-y-100"
                } transform transition-transform duration-200 ease-in-out`}
              >
                <path
                  d="M9.6 0.106172L9.6 3.64617L5.12 8.00617L0.64 3.64617L0.64 0.106172L5.12 4.48617L9.6 0.106172Z"
                  fill="white"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              onClick={() => setShowDropdown(false)}
              className={`${
                showDropdown ? "scale-y-100" : "scale-y-0"
              } transform origin-top transition-transform duration-200 ease-in-out absolute bg-black flex flex-col gap-5 py-4 text-white bg-opacity-85 rounded-lg w-[300px] mt-3 p-2 shadow`}
            >
              <li className="cursor-pointer pl-7 hover:bg-white rounded-xl hover:bg-opacity-20">
                <a href={links.servicePages.financialConsulting}>
                  Financial Consulting
                </a>
              </li>
              <li className="cursor-pointer pl-7 hover:bg-white rounded-xl hover:bg-opacity-20">
                <a href={links.servicePages.capitalProcurement}>
                  Capital Procurement
                </a>
              </li>
              <li className="cursor-pointer pl-7 hover:bg-white rounded-xl hover:bg-opacity-20">
                <a href={links.servicePages.riskManagement}>Risk Management</a>
              </li>
              <li className="cursor-pointer pl-7 hover:bg-white rounded-xl hover:bg-opacity-20">
                <a href={links.servicePages.sportsAndEntertainment}>
                  Sports & Entertainment
                </a>
              </li>
              <li className="cursor-pointer pl-7 hover:bg-white rounded-xl hover:bg-opacity-20">
                <a href={links.servicePages.communityAndMentorship}>
                  Community & Mentorship
                </a>
              </li>
              <li className="cursor-pointer pl-7 hover:bg-white rounded-xl hover:bg-opacity-20">
                <a href={links.servicePages.servingOthers}>Serving Others</a>
              </li>
            </ul>
          </div>

          <div className="flex justify-center items-center w-full ">
            <a href="/" className=" w-fit">
              <img
                src={require("assets/logo.png")}
                alt=""
                className="w-48 h-auto hidden md:block"
              />
            </a>
          </div>
          <nav className="flex gap-10 items-center justify-between self-stretch my-auto   text-nowrap w-full lg:w-auto">
            <a href="/" className=" w-fit">
              <img src={require("assets/logo.png")} alt="" className="w-48" />
            </a>
            <a
              href="/#services"
              className="flex-auto hidden md:block hover:text-brand-darkYellow"
            >
              How We Work
            </a>
            <a
              href="/about"
              className="hover:text-brand-darkYellow hidden md:block"
            >
              About Me
            </a>
            <img
              onClick={() => setShowMenu(true)}
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d301b55c6fd47890cf2824fb714376236d6e061ce1568943b3e87a7bac196d0c?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68& "
              alt=""
              className="shrink-0  self-center w-[27px] cursor-pointer  md:hidden"
            />
          </nav>
        </div>
      </header>

      {showMenu && <Menu hideMenu={() => setShowMenu(false)} />}
    </>
  );
};

export default Header;
