const ServiceCenterTextBlock = ({ title, bodyText }) => {
  return (
    <section className="flex flex-col text-center mb-20 gap-7 justify-center items-center">
      <h2 className="text-lg md:text-[3vw] font-semibold">{title}</h2>
      <p className=" text-sm md:text-3xl md:leading-[45px] max-w-[70%] text-balance">
        {bodyText}
      </p>
    </section>
  );
};

export default ServiceCenterTextBlock;
