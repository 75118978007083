import ServiceBlock from "components/Services/ServiceBlock";
import ServiceHero from "components/Services/ServiceHero";
import InfoBlock from "components/Services/InfoBlock";
import Contact from "components/home/Contact";
import Footer from "components/home/Footer";
import Header from "components/home/Header";

const FinancialConsulting = () => {
  const pageData = {
    hero: {
      title: "Financial Consulting",
      description:
        "Thank you for exploring my Financial Consulting Services, where we simplify complex financial challenges into clear, actionable strategies.",
      bgImgClass:
        "bg-[url('assets/services/hero-images/financial-consulting.png')]",
    },
    serviceBlocks: [
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 124"
            fill="none"
          >
            <rect
              x="0.689453"
              y="0.850464"
              width="100%"
              height="122.197"
              rx="20.1978"
              fill="url(#paint0_linear_653_215)"
            />
            <path
              d="M85.6744 56.027H76.1374C68.3164 56.027 61.9474 49.658 61.9474 41.837V32.3C61.9474 30.485 60.4624 29 58.6474 29H44.6554C34.4914 29 26.2744 35.6 26.2744 47.381V76.619C26.2744 88.4 34.4914 95 44.6554 95H70.5934C80.7574 95 88.9744 88.4 88.9744 76.619V59.327C88.9744 57.512 87.4894 56.027 85.6744 56.027Z"
              fill="white"
            />
            <path
              d="M70.1638 29.6931C68.8108 28.3401 66.4678 29.2641 66.4678 31.1451V42.6621C66.4678 47.4801 70.5598 51.4731 75.5428 51.4731C78.6778 51.5061 83.0338 51.5061 86.7628 51.5061C88.6438 51.5061 89.6338 49.2951 88.3138 47.9751C83.5618 43.1901 75.0478 34.5771 70.1638 29.6931Z"
              fill="white"
            />
            <path
              d="M62.5748 67.775H42.7748C41.4218 67.775 40.2998 66.653 40.2998 65.3C40.2998 63.947 41.4218 62.825 42.7748 62.825H62.5748C63.9278 62.825 65.0498 63.947 65.0498 65.3C65.0498 66.653 63.9278 67.775 62.5748 67.775Z"
              fill="#FFC44D"
            />
            <path
              d="M55.9748 80.9749H42.7748C41.4218 80.9749 40.2998 79.8529 40.2998 78.4999C40.2998 77.1469 41.4218 76.0249 42.7748 76.0249H55.9748C57.3278 76.0249 58.4498 77.1469 58.4498 78.4999C58.4498 79.8529 57.3278 80.9749 55.9748 80.9749Z"
              fill="#FFC44D"
            />
            <defs>
              <linearGradient
                id="paint0_linear_653_215"
                x1="92.5893"
                y1="0.850463"
                x2="57.7482"
                y2="123.047"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFC44D" />
                <stop offset="1" stop-color="#FFC44D" />
              </linearGradient>
            </defs>
          </svg>
        ),
        title: "Financial Planning",
        description:
          "I create tailored plans to meet your unique financial goals, whether you're saving for retirement, investing in your future, or managing debt.",
      },
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 123"
            fill="none"
          >
            <rect
              x="0.689453"
              y="0.112671"
              width="100%"
              height="122.197"
              rx="20.1978"
              fill="#FFC44D"
            />
            <path
              d="M66.7116 50.4878L64.3266 49.2435C63.3876 48.7509 62.6934 47.8407 62.3679 46.6223C62.0482 45.4529 62.1893 44.3093 62.7625 43.4078L64.2085 41.138C64.5858 40.5446 64.4994 39.7698 64.004 39.2744L58.655 33.9254C58.1567 33.43 57.3819 33.3435 56.7914 33.7209L54.5188 35.1668C53.6143 35.7401 52.4766 35.8783 51.2524 35.5471C50.0858 35.2331 49.1727 34.5418 48.6831 33.5999L47.4387 31.2149C47.1132 30.5927 46.396 30.2788 45.7219 30.4602L38.4144 32.416C37.7347 32.5975 37.2738 33.2283 37.3054 33.9282L37.4293 36.7568C37.4754 37.811 37.0692 38.8451 36.3117 39.5969C36.3117 39.5998 36.136 39.7726 36.136 39.7755C35.3842 40.5302 34.3472 40.945 33.2929 40.8932L30.4644 40.7693C29.7183 40.7664 29.1336 41.2014 28.9522 41.8783L26.9964 49.1858C26.8149 49.8627 27.1289 50.5771 27.751 50.9026L30.136 52.1469C31.075 52.6366 31.7692 53.5497 32.0976 54.7681C32.4173 55.9375 32.2762 57.0811 31.703 57.9826L30.257 60.2524C29.8797 60.8458 29.9661 61.6206 30.4615 62.116L35.8105 67.465C36.3088 67.9633 37.0836 68.0469 37.6741 67.6695L39.9439 66.2264C40.8484 65.6532 41.989 65.5092 43.2103 65.8433C44.3768 66.1573 45.2871 66.8486 45.7796 67.7906L47.024 70.1755C47.2861 70.6796 47.8045 70.9791 48.3518 70.9791C48.4814 70.9791 48.611 70.9619 48.7378 70.9273L56.0453 68.9715C56.7251 68.79 57.1859 68.1621 57.1543 67.4593L57.0333 64.6278C56.9872 63.5736 57.3934 62.5396 58.1509 61.7877C58.1509 61.7848 58.3266 61.612 58.3295 61.6091C59.0813 60.8545 60.124 60.4627 61.1725 60.4915L64.0011 60.6154C64.7673 60.6413 65.3318 60.1833 65.5133 59.5064L67.472 52.1989C67.6535 51.5219 67.3395 50.8076 66.7173 50.4821L66.7116 50.4878ZM47.2285 58.1868C43.0922 58.1868 39.7366 54.834 39.7366 50.6949C39.7366 46.5587 43.0894 43.203 47.2285 43.203C51.3648 43.203 54.7204 46.5558 54.7204 50.6949C54.7204 54.8312 51.3676 58.1868 47.2285 58.1868Z"
              fill="white"
            />
            <path
              d="M86.0633 51.3432C85.4613 50.7412 84.4906 50.7412 83.8915 51.3432L83.7042 51.5305V50.0355C83.7042 44.58 79.2655 40.141 73.8097 40.141H71.4132C70.5635 40.141 69.8779 40.8294 69.8779 41.6762C69.8779 42.5231 70.5635 43.2115 71.4132 43.2115H73.8097C77.5715 43.2115 80.6305 46.2705 80.6305 50.0323V51.5302L80.4433 51.3429C79.8413 50.7409 78.8706 50.7409 78.2715 51.3429C77.6694 51.9449 77.6694 52.9157 78.2715 53.5148L81.0799 56.3232C81.3679 56.6112 81.7596 56.7725 82.1658 56.7725C82.572 56.7725 82.9637 56.6112 83.2517 56.3232L86.0601 53.5148C86.6621 52.9128 86.6621 51.9421 86.0601 51.3429L86.0633 51.3432Z"
              fill="white"
            />
            <path
              d="M53.2225 86.4702H50.826C47.0641 86.4702 44.0051 83.4112 44.0051 79.6493V78.1515L44.1924 78.3387C44.4919 78.6383 44.8865 78.7881 45.2783 78.7881C45.6729 78.7881 46.0646 78.6383 46.3642 78.3387C46.9662 77.7367 46.9662 76.766 46.3642 76.1669L43.5558 73.3585C42.9768 72.7795 41.96 72.7853 41.3839 73.3585L38.5755 76.1669C37.9735 76.7689 37.9735 77.7396 38.5755 78.3387C39.1775 78.9407 40.1483 78.9407 40.7474 78.3387L40.9346 78.1515V79.6464C40.9346 85.102 45.3733 89.541 50.8292 89.541H53.2257C54.0754 89.541 54.7609 88.8526 54.7609 88.0057C54.7609 87.1589 54.0754 86.4705 53.2257 86.4705L53.2225 86.4702Z"
              fill="white"
            />
            <path
              d="M89.5053 76.0169L88.0392 70.5412C87.8635 69.8844 87.3018 69.4553 86.5731 69.4639L84.4502 69.5532C83.7531 69.5763 83.0676 69.3141 82.552 68.7957L82.4367 68.6805C81.9356 68.1821 81.6677 67.4995 81.6965 66.8024L81.7858 64.6796C81.8146 63.9998 81.3681 63.3862 80.7085 63.2134L75.2328 61.7473C74.5761 61.5716 73.8848 61.8741 73.565 62.4789L72.6317 64.2677C72.3063 64.8898 71.6985 65.3478 70.8747 65.5696C70.0854 65.7828 69.3279 65.6935 68.7316 65.3133L67.0293 64.2274C66.4561 63.8615 65.7043 63.9451 65.2204 64.4261L61.2108 68.4357C60.7298 68.9167 60.6462 69.6713 61.0121 70.2446L62.098 71.9469C62.4782 72.5432 62.5675 73.3036 62.3428 74.1274C62.1297 74.9109 61.6717 75.5216 61.0495 75.847L59.2608 76.7803C58.6559 77.0942 58.3506 77.7884 58.5292 78.4481L59.9953 83.9237C60.171 84.5805 60.7586 85.0327 61.4614 85.001L63.5843 84.9088C64.2727 84.8771 64.9669 85.1479 65.4595 85.6433L65.6006 85.7845C66.1018 86.2828 66.3697 86.9684 66.3409 87.6654L66.2516 89.7883C66.2228 90.468 66.6693 91.0816 67.3289 91.2544L72.8045 92.7205C72.9284 92.755 73.0551 92.7695 73.1819 92.7695C73.7147 92.7695 74.2159 92.4785 74.4723 91.9889L75.4056 90.2001C75.7311 89.578 76.3417 89.12 77.1627 88.8982C77.949 88.6821 78.7123 88.7743 79.3086 89.1545L81.0109 90.2376C81.5841 90.6005 82.3359 90.5227 82.8199 90.0388L86.8294 86.0292C87.3104 85.5482 87.394 84.7936 87.0282 84.2203L85.9422 82.5209C85.562 81.9246 85.4727 81.1613 85.6974 80.3375C85.9077 79.554 86.3685 78.9434 86.9907 78.6208L88.7766 77.6875C89.3814 77.3735 89.6867 76.6793 89.5082 76.0197L89.5053 76.0169ZM74.017 83.0509C70.8025 83.0509 68.1986 80.4441 68.1986 77.2324C68.1986 74.0179 70.8053 71.4139 74.017 71.4139C77.2316 71.4139 79.8355 74.0207 79.8355 77.2324C79.8355 80.447 77.2287 83.0509 74.017 83.0509Z"
              fill="white"
            />
          </svg>
        ),
        title: "Investment Strategies",
        description:
          "I receive expert advice on crafting a diverse investment portfolio. I can help you partner with top financial advisors for personalized guidance. and achieve your financial goals effectively.",
      },
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 124"
            fill="none"
          >
            <rect
              x="0.103516"
              y="0.850525"
              width="100%"
              height="122.197"
              rx="20.1978"
              fill="url(#paint0_linear_653_226)"
            />
            <path
              d="M65.6201 31.9998H79.2156C83.5142 31.9998 86.9989 35.4846 86.9989 39.7834V53.3789"
              stroke="white"
              stroke-width="6.61603"
              stroke-linecap="round"
            />
            <path
              d="M27 53.3793V39.7838C27 35.485 30.4848 32.0002 34.7836 32.0002H48.3791"
              stroke="white"
              stroke-width="6.61603"
              stroke-linecap="round"
            />
            <path
              d="M86.9993 70.6206V84.2161C86.9993 88.5147 83.5146 91.9994 79.2156 91.9994H65.6201"
              stroke="white"
              stroke-width="6.61603"
              stroke-linecap="round"
            />
            <path
              d="M48.3791 91.9998H34.7836C30.4848 91.9998 27 88.5147 27 84.2161V70.6206"
              stroke="white"
              stroke-width="6.61603"
              stroke-linecap="round"
            />
            <path
              d="M56.3824 54.2939C54.9307 54.6909 54.0557 55.8048 54.0557 56.8572C54.0557 57.9097 54.9307 59.0235 56.3824 59.4205V54.2939Z"
              fill="white"
            />
            <path
              d="M58.71 62.0498V67.1763C60.1617 66.7794 61.0367 65.6655 61.0367 64.6131C61.0367 63.5606 60.1617 62.4467 58.71 62.0498Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M73.0579 60.7353C73.0579 69.3019 66.1131 76.2467 57.5465 76.2467C48.9798 76.2467 42.0352 69.3019 42.0352 60.7353C42.0352 52.1686 48.9798 45.2239 57.5465 45.2239C66.1131 45.2239 73.0579 52.1686 73.0579 60.7353ZM57.5465 50.2651C58.189 50.2651 58.7099 50.786 58.7099 51.4285V51.9198C61.2388 52.3726 63.3633 54.2727 63.3633 56.8575C63.3633 57.5 62.8424 58.0208 62.1999 58.0208C61.5574 58.0208 61.0366 57.5 61.0366 56.8575C61.0366 55.8051 60.1616 54.6911 58.7099 54.2942V59.6754C61.2388 60.1283 63.3633 62.0283 63.3633 64.6131C63.3633 67.198 61.2388 69.0979 58.7099 69.5509V70.0421C58.7099 70.6846 58.189 71.2055 57.5465 71.2055C56.904 71.2055 56.3832 70.6846 56.3832 70.0421V69.5509C53.8541 69.0979 51.7298 67.198 51.7298 64.6131C51.7298 63.9707 52.2506 63.4498 52.8931 63.4498C53.5356 63.4498 54.0565 63.9707 54.0565 64.6131C54.0565 65.6656 54.9315 66.7795 56.3832 67.1764V61.7952C53.8541 61.3423 51.7298 59.4423 51.7298 56.8575C51.7298 54.2727 53.8541 52.3726 56.3832 51.9198V51.4285C56.3832 50.786 56.904 50.2651 57.5465 50.2651Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_653_226"
                x1="90.9935"
                y1="5.89997"
                x2="63.7265"
                y2="89.7207"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFC44D" />
                <stop offset="1" stop-color="#FFC44D" />
              </linearGradient>
            </defs>
          </svg>
        ),
        title: "Risk Management",
        description:
          "Prophete Risk Management also offers potential tax benefits, and I can ensure your asset`s safety and financial stability. it`s not rocket science; it`s ART",
      },
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 123"
            fill="none"
          >
            <rect
              x="0.103516"
              y="0.112549"
              width="100%"
              height="122.197"
              rx="20.1978"
              fill="#FFC44D"
            />
            <path
              d="M87.1468 89.2941H84.3674V55.9413C84.3674 53.9031 82.6997 52.2355 80.6615 52.2355H73.2498C71.2116 52.2355 69.5439 53.9031 69.5439 55.9413V89.2941H64.9116V67.0589C64.9116 65.0207 63.2439 63.3531 61.2057 63.3531H53.794C51.7558 63.3531 50.0881 65.0207 50.0881 67.0589V89.2941H45.4558V78.1765C45.4558 76.1383 43.7882 74.4707 41.7499 74.4707H34.3382C32.3 74.4707 30.6323 76.1383 30.6323 78.1765V89.2941H27.8529C26.8153 89.2941 26 90.1094 26 91.1471C26 92.1847 26.8153 93 27.8529 93H87.1468C88.1844 93 88.9997 92.1847 88.9997 91.1471C88.9997 90.1094 88.1844 89.2941 87.1468 89.2941Z"
              fill="white"
            />
            <path
              d="M38.9701 49.8264C37.9325 49.8264 37.1172 50.6417 37.1172 51.6793C37.1172 52.717 37.9325 53.5322 38.9701 53.5322C52.5336 53.5322 65.9859 47.5658 75.1023 36.2259V39.2647C75.1023 40.3023 75.9176 41.1176 76.9552 41.1176C77.9929 41.1176 78.8082 40.3023 78.8082 39.2647V31.8529C78.8082 30.8153 77.9929 30 76.9552 30H69.5435C68.5059 30 67.6906 30.8153 67.6906 31.8529C67.6906 32.8906 68.5059 33.7059 69.5435 33.7059H72.36C64.0218 44.1564 51.6812 49.8264 38.9701 49.8264Z"
              fill="white"
            />
          </svg>
        ),
        title: "Capital Raising",
        description:
          "I receive guidance on securing the capital you need for your business ventures, from initial funding to expansion plans. With my expert financial advice, you can ensure your business gorws",
      },
    ],
  };
  return (
    <main>
      <div
        className={`${pageData.hero.bgImgClass} bg-[url('assets/hero-bg.png')] bg-cover  bg-no-repeat  bg-[top_right_.0001rem] w-full h-[55vh] md:h-[90vh] relative flex justify-center`}
      >
        <Header hasInitialTransparentBgOpacity />
        <ServiceHero
          title={pageData.hero.title}
          description={pageData.hero.description}
        />
      </div>
      <section className="px-[8%] py-[5%] grid grid-cols-1 items-center justify-center md:grid-cols-2 gap-5 md:gap-10 ">
        <ServiceBlock {...pageData.serviceBlocks[0]} />
        <ServiceBlock {...pageData.serviceBlocks[1]} />
        <ServiceBlock {...pageData.serviceBlocks[2]} />
        <ServiceBlock {...pageData.serviceBlocks[3]} />
        <InfoBlock
          title={"Why Prophete?"}
          description={
            "With years of experience at the country’s largest investment bank (Goldman Sachs), I bring a wealth of knowledge and a proven track record in financial consulting.\n\nMy approach is authentic & dedicated, making complex financial concepts accessible & engaging."
          }
          img={require("assets/services/why-prophete.png")}
        />
      </section>
      <Contact
        actionBtnText={"Work with me"}
        bodyText={
          "Ready to take control of your financial future? Let’s work together to create a strategy that works for you. "
        }
      />
      <Footer />
    </main>
  );
};

export default FinancialConsulting;
