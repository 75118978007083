export const links = {
  tiktok: "www.tiktok.com",
  facebook: "https://www.facebook.com/dimitry.p.prophete/",
  instagram: "https://www.instagram.com/dimitrytheconnector/",
  youtube: "www.youtube.com",
  linkedin: "https://www.linkedin.com/in/dimitry-prophete/",
  servicePages: {
    financialConsulting: "/services/financial-consulting",
    capitalProcurement: "/services/capital-procurement",
    riskManagement: "/services/risk-management",
    sportsAndEntertainment: "/services/sports-and-entertainment",
    communityAndMentorship: "/services/community-and-mentorship",
    servingOthers: "/services/serving-others",
  },
};
