import React from "react";

const TrustedBrands = () => {
  const brandImages = [
    { img: require("assets/brands/1.png"), alt: "ExCel" },
    { img: require("assets/brands/2.png"), alt: "Goldman Sachs" },
    { img: require("assets/brands/3.png"), alt: "Salesmrkt" },
    { img: require("assets/brands/4.png"), alt: "Nova" },
    { img: require("assets/brands/5.png"), alt: "Stella Jets" },
    { img: require("assets/brands/6.png"), alt: "The Source" },
    { img: require("assets/brands/7.png"), alt: "Live One" },
    { img: require("assets/brands/8.png"), alt: "Podcastone" },
    { img: require("assets/brands/9.png"), alt: "Island Wave" },
    { img: require("assets/brands/10.png"), alt: "Thinnex" },
    { img: require("assets/brands/11.png"), alt: "Bomani" },
  ];

  return (
    <section className="flex flex-col justify-center items-center px-20 md:pt-10 pb-4  mt-16 md:mt-10 w-full bg-stone-50 max-md:px-2  max-md:max-w-full">
      <h2 className="text-5xl font-semibold leading-10 text-center text-brand-darkYellow uppercase max-md:max-w-full max-md:text-4xl">
        TRUSTED BY AMAZING BRANDS
      </h2>
      <div className="w-[90%] mx-auto bg-black h-[1px] md:opacity-20 mt-5 md:mt-10" />

      <div
        id="brand-list"
        className="flex flex-wrap justify-center items-center px-5 md:pt-7 pb-9 mt-10 md:mt-3 max-md:px-2 max-md:max-w-full gap-x-[5%] md:gap-x-[10%] gap-y-10 max-w-[75vw]"
      >
        {brandImages.map((elem, index) => {
          return (
            <img
              src={elem.img}
              alt={elem.alt}
              className="w-full max-w-[24vw] lg:max-w-[12vw]"
            />
          );
        })}
      </div>
    </section>
  );
};

export default TrustedBrands;
