/**
 * This code was generated by Builder.io.
 */
import React from "react";

const ServiceCard = ({ title, description, iconSrc, imageSrc, link }) => {
  return (
    <a
      href={link}
      className="flex flex-col grow  max-md:max-w-full cursor-pointer"
    >
      <div className="flex flex-col  max-md:flex-wrap">
        <div className="flex  justify-between gap-5 max-md:max-w-full">
          <h3 className="text-xs md:text-lg max-w-40 md:max-w-fit max-md:whitespace-nowrap max-md:overflow-hidden max-md:text-ellipsis    lg:text-4xl font-bold text-black  lg:h-[80px]">
            {title}
          </h3>
          <img
            loading="lazy"
            src={iconSrc}
            alt=""
            className="size-5 aspect-[1.03] lg:size-9"
          />
        </div>
        <p
          className="md:mt-1 self-start
         text-[.5rem] md:text-sm lg:text-xl text-nowrap text-zinc-500 max-md:max-w-full"
        >
          {description}
        </p>
      </div>
      <img
        loading="lazy"
        src={imageSrc}
        alt={title}
        className="mt-3 lg:mt-5 aspect-[0.96] max-md:max-w-full"
      />
    </a>
  );
};

export default ServiceCard;
