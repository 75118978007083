/**
 * This code was generated by Builder.io.
 */
import React from "react";

const LocationSection = () => {
  return (
    <section className="flex overflow-hidden    relative flex-col items-end w-full text-xl font-medium tracking-tight leading-8 text-center text-white min-h-[320px]  max-h-[485px]  max-md:max-w-full">
      <img
        loading="lazy"
        src={require("assets/location-desktop.png")}
        alt="Location background"
        className="object-cover object-right  hidden md:block absolute inset-0 w-full h-full"
      />
      <img
        loading="lazy"
        src={require("assets/location-mobile.png")}
        alt="Location background"
        className="object-cover h-full w-full block lg:hidden inset-0"
      />
    </section>
  );
};

export default LocationSection;
