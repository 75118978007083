import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useRef } from "react";
import TestimonialItem from "./home/TestimonialItem";
const data = [
  {
    name: "Hilmon Sorey",
    title: "Cofounder of The Source Groups",
    description:
      "Dimitry played a crucial role in resolving a significant issue for one of our portfolio companies. After weeks of searching for a solution, we reached out to him. He quickly connected us with five merchant service companies through his direct relationships, enabling us to secure a high-risk account in less than a week.",
    img: require("assets/testimonials/hilmon.png"),
  },
  {
    name: "Sam Madani",
    title: "Founder of Bomani",
    description:
      "Dimitry has a deep understanding of our business and introduced us to several key individuals and family offices who invested directly in our company. His expertise is outstanding, and beyond that, he is a fantastic friend and a remarkable connector.",
    img: require("assets/testimonials/sam.png"),
  },
  {
    name: "Mendel Davis",
    title: "Founder of Nova Risk Transfer",
    description:
      "Since partnering with Dimitry and Prophete Risk Management in May 2024, Nova has seen more progress and opportunities in just a few months than we have in the past five years. Dimitry quickly grasped our business model and effectively communicated with potential clients, thanks to his extensive connections and insightful approach.",
    img: require("assets/testimonials/mendel.png"),
  },
  {
    name: "Tia Minzoni",
    title: "Founder of Stella Jets",
    description:
      "Since our introduction to Dimitry in June 2024, he has made over fifteen direct introductions to top-tier celebrities who could potentially become clients for Stella Jets. His genuine concern for the success of my business and his heartfelt approach are truly evident.",
    img: require("assets/testimonials/tia.png"),
  },
  {
    name: "Brian Weiner",
    title: "CEO of Excel Global Family Office",
    description:
      "Dimitry has been a fantastic referral partner for Excel Global Family Office, connecting us with over 20 families interested in our services. His deep understanding of trust, tax, and estate planning, honed by his family office experience, has been invaluable.",
    img: require("assets/testimonials/brian.png"),
  },
  {
    name: "Joseph Grecor",
    title: "Founder & CEO of Salesmrkt",
    description:
      "For businesses seeking access to a wide network of investors, high-net-worth individuals, potential partners, and clients, Dimitry is the go-to person. His vast expertise and connections opened many doors for Salesmrkt. Moreover, Dimitry’s positivity, energy, and relentless effort make him an exceptional asset to any team.",
    img: require("assets/testimonials/joseph.png"),
  },
];

const TestimonialSwiper = () => {
  const swiperRef = useRef();

  const breakpoints = {
    640: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 1.5,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 15,
    },
    1200: {
      slidesPerView: 2.5,
      spaceBetween: 30,
    },
  };

  return (
    <>
      <Swiper
        breakpoints={breakpoints}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {data.map((dataItem, index) => {
          return (
            <SwiperSlide key={index}>
              <TestimonialItem {...dataItem} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="flex w-full ml-[78%] m md:ml-[75vw] md:mt-5 md:mb-8 text-4xl md:text-6xl font-bold font-secondary gap-7 md:gap-10">
        <button onClick={() => swiperRef.current.slidePrev()}> &lt; </button>
        <button onClick={() => swiperRef.current.slideNext()}> &gt;</button>
      </div>
    </>
  );
};

export default TestimonialSwiper;
