/**
 * This code was generated by Builder.io.
 */
import React from "react";
import SocialLinksBar from "./SocialLinksBar";

const Hero = () => {
  return (
    <section
      id="hero"
      className="flex flex-col self-center mb-16 w-full max-md:my-10 max-md:max-w-full text-white pt-[100px]"
    >
      <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full z-40 pl-4 md:pl-[12%] pr-[5%] py-[2%]">
        <div className="flex flex-col max-md:max-w-full">
          <h1 className="tracking-tighter lg:leading-[.6]  text-6xl md:text-7xl lg:text-9xl max-md:max-w-full  font-secondary">
            Turning <br /> Connections  <br /> Into Success <br /> Stories
          </h1>
          <p className="mt-5 md:mt-14 text-[3.5vw] md:text-[1.3vw] leading-normal  max-md:max-w-full max-w-[600px]">
            We put you into the center of capital, network & opportunity to grow
            and protect your portfolio.
          </p>
        </div>
        <div className="hidden lg:block">
          <SocialLinksBar />
        </div>
      </div>
      <div className="w-full md:w-auto mx-auto  md:ml-[12%] md:mr-[5%] flex flex-row items-center justify-center">
        <button className="text-brand-lightYellow  w-11/12 items-start px-9 py-4  max-w-full text-xl font-semibold text-center bg-black bg-opacity-50 rounded-[105.907px] md:w-[250px] max-md:mt-10 z-10 text-nowrap">
          Let's Connect
        </button>
        <div className="h-[2.5px] w-full bg-white mx-4 hidden md:block"></div>
        <div className="text-nowrap font-secondary text-[45px] hidden md:block">
          Dimitry The Connector
        </div>
      </div>
    </section>
  );
};

export default Hero;
