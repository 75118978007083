import ServiceBlock from "components/Services/ServiceBlock";
import ServiceHero from "components/Services/ServiceHero";
import Contact from "components/home/Contact";
import Footer from "components/home/Footer";
import Header from "components/home/Header";
import InfoBlock from "components/Services/InfoBlock";
import ServiceCenterTextBlock from "components/Services/ServiceCenterTextBlock";

const RiskManagement = () => {
  const pageData = {
    hero: {
      title: "Risk Management",
      description:
        "We navigate the complexities of risk to protect and enhance your financial stability.",
      bgImgClass: "bg-[url('assets/services/hero-images/risk-management.png')]",
    },
    serviceBlocks: [
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 124"
            fill="none"
          >
            <rect
              x="0.689453"
              y="0.850464"
              width="100%"
              height="122.197"
              rx="20.1978"
              fill="url(#paint0_linear_663_300)"
            />
            <g clip-path="url(#clip0_663_300)">
              <path
                d="M59.9702 89.6465C59.5473 89.6465 59.2397 89.6465 58.9129 89.6465C50.9352 89.6465 42.9574 89.6465 34.9796 89.6465C32.9035 89.6465 32 88.7609 32 86.6623C32 71.6066 32 56.5701 32 41.5144C32 39.4351 32.8458 38.6072 34.922 38.6072C36.3061 38.6072 37.6709 38.6072 39.2088 38.6072C39.2088 39.5698 39.2088 40.494 39.2088 41.3988C39.228 43.2471 40.1508 44.1713 41.9962 44.1713C50.2047 44.1713 58.3939 44.1713 66.6023 44.1713C68.5055 44.1713 69.409 43.2471 69.4282 41.3411C69.4282 40.4747 69.4282 39.6276 69.4282 38.6649C71.2737 38.6649 73.0422 38.5879 74.8108 38.7034C75.7335 38.7612 76.3294 39.5121 76.5217 40.4362C76.5986 40.802 76.6178 41.2063 76.6178 41.5914C76.6178 51.7376 76.6178 61.8646 76.6178 72.0109C76.6178 72.2997 76.5793 72.5692 76.5601 72.9543C71.389 71.3371 66.6985 72.0109 62.8345 75.8615C59.009 79.6928 58.2978 84.3905 59.9702 89.6465Z"
                fill="white"
              />
              <path
                d="M60.7197 84.8526C60.7197 78.6147 65.6794 73.6667 71.8886 73.686C78.0209 73.7052 82.9998 78.6917 82.9998 84.8526C82.9998 91.0328 78.0017 96.0386 71.7925 96.0193C65.6409 96 60.7197 91.0328 60.7197 84.8526ZM70.7544 87.4518C69.3126 86.4314 68.0054 85.488 66.6598 84.5446C65.6217 83.813 64.7182 83.9092 64.1415 84.7564C63.584 85.565 63.8147 86.4121 64.8143 87.1245C66.4099 88.2604 68.0054 89.3963 69.5818 90.5322C71.0043 91.5526 71.6002 91.4756 72.7152 90.0702C74.5414 87.7983 76.3485 85.5072 78.1747 83.2354C79.1935 81.9455 79.2512 81.0983 78.3477 80.4052C77.4634 79.7314 76.6753 80.0009 75.6564 81.2909C74.0609 83.3317 72.4269 85.3532 70.7544 87.4518Z"
                fill="white"
              />
              <path
                d="M62.3736 34.5833C63.7577 34.5833 65.1225 34.5833 66.4874 34.5833C67.6793 34.5833 67.8523 34.7759 67.8715 35.9888C67.8715 37.7216 67.8715 39.4543 67.8715 41.1871C67.8715 42.3615 67.6793 42.554 66.5643 42.5733C58.3943 42.5733 50.2243 42.5733 42.0735 42.5733C40.9778 42.5733 40.8048 42.3807 40.7856 41.2641C40.7856 39.4351 40.8048 37.606 40.7663 35.777C40.7471 34.9106 41.1124 34.5641 41.9774 34.5833C43.3807 34.6218 44.7841 34.5833 46.245 34.5833C46.3412 34.0443 46.4181 33.5437 46.5334 33.0624C47.1486 30.4055 48.8979 29 51.5892 29C53.377 29 55.184 29 56.9718 29C59.8937 29 61.7008 30.5402 62.1813 33.4474C62.239 33.794 62.2967 34.1405 62.3736 34.5833Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_663_300"
                x1="92.5893"
                y1="0.850463"
                x2="57.7482"
                y2="123.047"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFC44D" />
                <stop offset="1" stop-color="#FFC44D" />
              </linearGradient>
              <clipPath id="clip0_663_300">
                <rect
                  width="51"
                  height="67"
                  fill="white"
                  transform="translate(32 29)"
                />
              </clipPath>
            </defs>
          </svg>
        ),
        title: "Comprehensive Risk Assessments",
        description:
          "I will conduct thorough evaluations of your financial portfolio to identify potential risks. Analyse market trends, economic indicators, & other factors that may impact your investments.",
      },
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 124"
            fill="none"
          >
            <rect
              x="0.103516"
              y="0.850464"
              width="100%"
              height="122.197"
              rx="20.1978"
              fill="url(#paint0_linear_663_301)"
            />
            <g clip-path="url(#clip0_663_301)">
              <path
                d="M79.8717 45.5547C82.4223 49.1829 84.0673 53.0116 84.8066 57.3144C85.6753 57.3144 86.544 57.2961 87.3942 57.3144C89.0392 57.3508 89.9633 58.2442 89.9818 59.8669C90.0003 61.3619 90.0003 62.8387 89.9818 64.3337C89.9633 65.7923 89.0392 66.7039 87.5605 66.7586C86.6734 66.795 85.7862 66.7586 84.9175 66.7586C84.4739 68.3994 84.0488 69.9674 83.6237 71.5354C82.5332 70.6967 81.6276 69.858 80.5741 69.2564C79.5575 68.6729 78.3931 68.3447 77.2841 67.889C80.1674 56.6215 74.4193 47.8884 67.6916 43.8591C60.2615 39.3923 50.8538 39.7569 43.701 44.9713C36.9178 49.8939 33.2582 58.4448 35.7718 67.889C34.6444 68.3447 33.4615 68.6912 32.408 69.2746C31.3914 69.858 30.5043 70.6785 29.3953 71.5171C28.9702 69.9856 28.5451 68.4177 28.083 66.7403C27.3067 66.7403 26.4565 66.7586 25.6063 66.7403C23.9614 66.7039 23.0372 65.7923 23.0187 64.1878C23.0003 62.7293 23.0003 61.2707 23.0187 59.8122C23.0372 58.2442 23.9614 57.3326 25.5509 57.2961C26.4011 57.2779 27.2698 57.2961 27.9906 57.2961C28.6745 55.163 29.192 53.1028 30.0237 51.1519C30.837 49.2376 31.9274 47.4326 33.0179 45.3541C32.5004 44.8983 31.835 44.3696 31.2436 43.768C30.0976 42.6193 30.0976 41.3978 31.2251 40.2309C32.2416 39.1735 33.2952 38.1343 34.3487 37.1315C35.5501 36.0011 36.8254 36.0011 38.0083 37.1315C38.5997 37.6967 39.1542 38.3166 39.6532 38.8453C41.7048 37.8061 43.5531 36.6939 45.5123 35.8917C47.453 35.0895 49.5415 34.5972 51.704 33.9044C51.704 33.2298 51.6855 32.3912 51.704 31.5525C51.741 29.9298 52.6466 29.0182 54.2916 29C55.8072 28.9818 57.3043 28.9818 58.8199 29C60.2985 29.0182 61.2226 29.9298 61.2781 31.3884C61.315 32.2635 61.2781 33.1387 61.2781 34.0867C65.603 34.8343 69.5214 36.4387 73.2549 39.0094C73.8463 38.3713 74.3823 37.6967 74.9923 37.1133C76.1382 36.0011 77.4135 36.0011 78.5779 37.095C79.6684 38.116 80.7404 39.1735 81.7754 40.2492C82.8844 41.3978 82.8474 42.6376 81.72 43.7862C81.1655 44.3878 80.5371 44.9348 79.8717 45.5547Z"
                fill="white"
              />
              <path
                d="M66.0833 74.8536C64.2905 70.5143 61.1854 67.9801 56.5462 67.9801C51.8516 67.9618 48.7465 70.4961 46.9537 74.7806C46.5655 74.0331 46.2329 73.3221 45.8262 72.6657C44.3106 70.1861 42.0558 68.6911 39.2094 68.1442C38.4701 67.9983 38.1929 67.7066 38.0265 67.0502C35.3835 57.3873 40.688 47.5055 50.3175 44.169C62.0911 40.085 74.8811 48.4718 75.602 60.7419C75.7313 62.948 75.528 65.0994 74.9551 67.2326C74.8257 67.7431 74.5669 67.9801 73.9755 68.1077C70.4453 68.8005 67.9131 70.7878 66.453 74.0513C66.3421 74.2701 66.2497 74.4889 66.0833 74.8536Z"
                fill="white"
              />
              <path
                d="M61.0194 83.842C65.5847 86.2304 67.5808 89.9497 67.2481 95C60.0953 95 52.9609 95 45.7527 95C45.5309 90.0226 47.564 86.3215 52.0183 83.842C49.8743 82.1282 48.7654 79.9585 48.9502 77.2602C49.098 75.2 49.9852 73.4679 51.5747 72.1188C54.6059 69.4933 59.282 69.7851 62.0729 72.7387C64.5496 75.3458 65.252 80.3414 61.0194 83.842Z"
                fill="white"
              />
              <path
                d="M69.8175 94.9999C69.6327 93.432 69.5403 91.8823 69.2076 90.3872C68.9673 89.2751 68.4498 88.2176 67.9323 87.1784C67.6735 86.6679 67.7105 86.3397 68.1356 86.0116C69.0967 85.264 70.0578 84.5165 71.0189 83.7872C66.5646 79.995 67.8584 74.7441 70.3351 72.4287C73.3293 69.6209 77.9315 69.6209 80.9257 72.4287C83.4948 74.8353 84.6407 80.1408 80.1679 83.8602C84.7146 86.1939 86.6553 89.9497 86.3966 94.9999C80.8517 94.9999 75.3069 94.9999 69.8175 94.9999Z"
                fill="white"
              />
              <path
                d="M41.9817 83.7691C43.0168 84.5531 44.0333 85.2823 44.9944 86.0663C45.1978 86.2304 45.3826 86.7227 45.2717 86.8868C43.7931 89.3663 43.2016 92.0464 43.331 94.9818C37.7122 94.9818 32.1674 94.9818 26.5302 94.9818C26.3823 90.0409 28.36 86.3033 32.7589 83.8785C28.9514 79.521 28.7851 75.3459 32.2783 72.21C35.2355 69.5481 39.8932 69.7304 42.7211 72.5381C45.2717 75.0177 46.2513 80.1774 41.9817 83.7691Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_663_301"
                x1="90.9935"
                y1="5.89991"
                x2="63.7265"
                y2="89.7207"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFC44D" />
                <stop offset="1" stop-color="#FFC44D" />
              </linearGradient>
              <clipPath id="clip0_663_301">
                <rect
                  width="67"
                  height="66"
                  fill="white"
                  transform="translate(23 29)"
                />
              </clipPath>
            </defs>
          </svg>
        ),
        title: "Customised Risk Management Plans",
        description:
          "I will develop tailored strategies to manage and mitigate identified risks. I will implement solutions that align with your financial goals and risk tolerance.",
      },
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 123"
            fill="none"
          >
            <rect
              x="0.689453"
              y="0.112793"
              width="114.117"
              height="122.197"
              rx="20.1978"
              fill="#FFC44D"
            />
            <g clip-path="url(#clip0_663_304)">
              <path
                d="M59.7428 30C72.9429 30.0179 86.0537 41.0831 86.0001 56.6459C85.9465 71.2984 74.1218 83.0061 59.3677 82.9705C44.7207 82.9348 32.896 71.0128 32.9317 56.2889C32.9496 42.1183 45.3459 29.9822 59.7428 30ZM59.7428 43.6531C57.3671 43.6888 55.045 44.0101 52.8123 44.7775C47.3286 46.6336 43.131 49.9889 40.6839 55.343C40.2909 56.1997 40.2909 56.985 40.7196 57.8238C44.2563 64.7485 49.8472 68.7819 57.6172 69.3887C66.4768 70.0848 73.2287 66.4797 77.8192 58.8411C78.7302 57.3419 78.8195 55.9855 77.9086 54.4328C73.836 47.4546 67.8165 43.8494 59.7428 43.6531Z"
                fill="white"
              />
              <path
                d="M25.4828 81.8103C28.4122 84.7372 31.3059 87.6463 34.2174 90.5733C32.5026 92.6078 30.5735 94.3033 27.6084 93.9642C25.1613 93.6787 23.393 92.3401 22.482 90.0736C21.571 87.7891 21.964 85.6296 23.5537 83.7378C24.1253 83.0418 24.8041 82.4706 25.4828 81.8103Z"
                fill="white"
              />
              <path
                d="M37.4859 87.0932C34.6637 84.2733 31.7522 81.3821 28.9121 78.5444C30.4483 77.0095 31.9844 75.4747 33.592 73.8684C35.8605 77.2951 38.7541 80.1863 42.1479 82.4351C40.5761 83.9878 39.0399 85.5405 37.4859 87.0932Z"
                fill="white"
              />
              <path
                d="M59.9032 64.8555C53.8837 64.6235 49.2395 62.6425 45.9708 57.913C45.2741 56.9135 45.3099 56.1461 46.0065 55.1466C48.5786 51.488 52.0796 49.3285 56.438 48.6146C61.8502 47.7401 66.7444 48.918 70.8349 52.6837C71.7994 53.5761 72.5139 54.754 73.3355 55.7891C73.7464 56.3067 73.6571 56.7886 73.3355 57.3419C71.0313 61.1611 67.6375 63.4099 63.3506 64.3736C62.0824 64.6413 60.7785 64.7306 59.9032 64.8555ZM59.5103 50.5956C55.9557 50.5956 53.4014 53.0764 53.3836 56.5209C53.3657 59.894 56.1165 62.6425 59.5103 62.6425C62.9041 62.6425 65.6548 59.9118 65.637 56.5209C65.6191 53.0764 63.047 50.5956 59.5103 50.5956Z"
                fill="white"
              />
              <path
                d="M60.9925 56.5924C60.9746 57.4313 60.3494 58.0202 59.492 58.0024C58.6347 58.0024 58.0273 57.3777 58.0273 56.5389C58.0273 55.6644 58.6882 55.0397 59.5813 55.0754C60.4209 55.1111 61.0103 55.7358 60.9925 56.5924Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_663_304">
                <rect
                  width="64"
                  height="64"
                  fill="white"
                  transform="translate(22 30)"
                />
              </clipPath>
            </defs>
          </svg>
        ),
        title: "Ongoing Monitoring",
        description:
          "I continuously monitor your portfolio to ensure it remains resilient against evolving risks. I will make proactive adjustments to your risk management plan as market conditions change.",
      },
      {
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 115 123"
            fill="none"
          >
            <rect
              x="0.103516"
              y="0.112549"
              width="100%"
              height="122.197"
              rx="20.1978"
              fill="#FFC44D"
            />
            <g clip-path="url(#clip0_663_306)">
              <path
                d="M48.8368 93.0001C44.6539 93.0001 40.471 93.0272 36.2881 93.0001C31.0797 92.9731 28.4081 88.3555 30.9988 83.7918C35.2087 76.4198 39.4725 69.0747 43.7634 61.7027C46.1382 57.6251 51.5355 57.5171 53.9373 61.5677C58.3361 69.0477 62.6809 76.5548 66.9448 84.1159C69.3466 88.3555 66.567 92.9191 61.6554 93.0001C61.5745 93.0001 61.4665 93.0001 61.3856 93.0001C57.2027 93.0001 53.0198 93.0001 48.8368 93.0001ZM48.0812 75.0426C48.0812 76.6088 48.0812 78.175 48.0812 79.7683C48.0812 80.3893 48.0272 81.1995 48.9448 81.0644C49.2686 81.0104 49.7004 80.2543 49.7004 79.7953C49.7544 76.6088 49.7544 73.4224 49.7004 70.2359C49.7004 69.8038 49.1877 69.3718 48.9178 68.9397C48.621 69.3448 48.1352 69.7498 48.1082 70.1819C48.0272 71.8021 48.0812 73.4224 48.0812 75.0426ZM48.9988 84.764C48.594 85.304 48.1622 85.6281 48.1892 85.8981C48.2162 86.1952 48.6749 86.4652 48.9448 86.7623C49.2147 86.4652 49.5925 86.1952 49.6734 85.8711C49.7274 85.6011 49.3496 85.277 48.9988 84.764Z"
                fill="white"
              />
              <path
                d="M75.0403 92.9461C72.5845 92.9461 70.3176 92.9461 67.8619 92.9461C68.1857 92.4331 68.4286 92.055 68.6984 91.65C70.4526 88.9226 70.6954 86.0332 69.0223 83.2518C68.0508 81.6315 67.7539 80.0113 67.7539 78.148C67.8079 67.0764 67.7809 56.0049 67.7809 44.9333C67.7809 41.6928 70.6145 40.0726 73.502 41.6118C74.7164 42.2599 75.0403 43.4211 75.0403 44.6903C75.0403 48.5518 75.0403 52.4404 75.0403 56.3019C75.0403 67.9676 75.0403 79.6332 75.0403 91.2719C75.0403 91.812 75.0403 92.3251 75.0403 92.9461Z"
                fill="white"
              />
              <path
                d="M65.6768 77.3648C63.4639 73.5033 61.2241 69.6417 59.0382 65.7802C58.7953 65.3481 58.7683 64.754 58.7683 64.214C58.7413 54.1415 58.7413 44.0421 58.7683 33.9696C58.7683 30.7292 61.5749 29.0279 64.4355 30.6482C65.1101 31.0262 65.5419 31.9714 65.9197 32.7275C66.1356 33.1325 66.0007 33.6996 66.0007 34.1857C66.0007 48.0927 66.0007 61.9996 66.0007 75.9336C66.0007 76.4197 66.0007 76.8788 66.0007 77.3648C65.9197 77.3378 65.7848 77.3378 65.6768 77.3648Z"
                fill="white"
              />
              <path
                d="M84.0002 92.919C81.4905 92.919 79.1696 92.919 76.7139 92.919C76.7139 92.3519 76.7139 91.8658 76.7139 91.4067C76.7139 80.0651 76.7139 68.7505 76.7139 57.4089C76.7139 54.2224 79.3316 52.5212 82.2731 53.8444C83.5954 54.4385 84.0002 55.5726 84.0002 56.9228C84.0002 61.9725 84.0002 66.9953 84.0002 72.045C84.0002 78.4719 84.0002 84.8988 84.0002 91.3257C84.0002 91.8118 84.0002 92.2979 84.0002 92.919Z"
                fill="white"
              />
              <path
                d="M56.7439 61.8106C54.9628 58.2461 52.2371 56.1938 47.9463 56.4098C47.9463 55.6537 47.9463 55.0056 47.9463 54.3576C47.9463 51.8462 47.9463 49.3078 47.9463 46.7965C47.9463 43.718 49.2416 42.3948 52.2911 42.4489C53.3436 42.4759 54.558 42.5299 55.3676 43.0699C56.1502 43.61 56.9328 44.7712 56.9598 45.6623C57.0947 50.9551 57.0407 56.2748 57.0407 61.5946C56.9328 61.6756 56.8248 61.7566 56.7439 61.8106Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_663_306">
                <rect
                  width="54"
                  height="63"
                  fill="white"
                  transform="translate(30 30)"
                />
              </clipPath>
            </defs>
          </svg>
        ),
        title: "Crisis Management",
        description:
          "Provide expert guidance during financial crises to minimize losses and protect your assets. Implement effective recovery strategies to help you bounce back stronger.",
      },
    ],
  };
  return (
    <main>
      <div
        className={`${pageData.hero.bgImgClass} bg-[url('assets/hero-bg.png')] bg-cover bg-no-repeat w-screen bg-[top_left_-15rem] md:bg-left-top h-[75vh] md:h-[90vh] relative flex justify-center`}
      >
        <Header hasInitialTransparentBgOpacity />
        <ServiceHero
          title={pageData.hero.title}
          description={pageData.hero.description}
        />
      </div>
      <section className="px-[8%] py-[5%] grid grid-cols-1 items-center justify-center md:grid-cols-2 gap-5 md:gap-10 ">
        <InfoBlock
          title={"My Approach"}
          description={
            "Risk is an inherent part of any financial journey, but with the right strategies, it can be managed effectively. With my extensive experience in the financial industry, I offer a comprehensive approach to identifying, assessing, and mitigating risks."
          }
          img={require("assets/services/dimitry-my-approach.png")}
        />
        <ServiceBlock {...pageData.serviceBlocks[0]} />
        <ServiceBlock {...pageData.serviceBlocks[1]} />
        <ServiceBlock {...pageData.serviceBlocks[2]} />
        <ServiceBlock {...pageData.serviceBlocks[3]} />
        <InfoBlock
          title={"Why Prophete?"}
          description="With years of experience at the largest investment bank in the country (Goldman Sachs), I bring a wealth of knowledge and a proven track record in capital raising. My approach is personalised and hands-on, ensuring that you are well-prepared and confident throughout the process."
          img={require("assets/services/why-prophete.png")}
        />
      </section>
      <ServiceCenterTextBlock
        title={"Your Financial Safety Net"}
        bodyText={
          "Risk management is not just about avoiding losses; it's about creating a safety net that will allow you to pursue your financial goals with confidence. Let me help you build that safety net and navigate the uncertainties of the financial world."
        }
      />
      <Contact
        actionBtnText={"Take me to the next level!"}
        bodyText={
          "Ready to take your business to the next level? Let's secure the funding you need to grow and succeed. Contact me today to get started."
        }
      />
      <Footer />
    </main>
  );
};

export default RiskManagement;
