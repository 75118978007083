import { NavLink } from "react-router-dom";

const navLinks = [
  { name: "HOME", link: "/" },
  { name: "HOW WE WORK", link: "#" },
  { name: "ABOUT", link: "/about" },
  { name: "FINANCIAL CONSULTING", link: "/services/financial-consulting" },
  { name: "CAPITAL PROCUREMENT ", link: "/services/capital-procurement" },
  { name: "RISK MANAGEMENT", link: "/services/risk-management" },
  {
    name: "Sports & Entertainment",
    link: "/services/sports-and-entertainment",
  },
  {
    name: "Community & Mentorship",
    link: "/services/community-and-mentorship",
  },
  {
    name: "Serving Others",
    link: "/services/serving-others",
  },
];

const Menu = ({ hideMenu }) => {
  return (
    <section>
      <div
        className="fixed inset-0 bg-black bg-opacity-70 z-40"
        onClick={hideMenu}
        id="backdrop"
      ></div>
      <section className="rounded-[28px] z-[100] p-4 max-h-fit   fixed top-[14%] left-[7%] inset-0 bg-[#2C2827] backdrop-brightness-200 backdrop-blur-xl bg-opacity-60 w-5/6">
        <div className="blur-none">
          <div className="flex px-2  justify-between items-center">
            <p className="text-lg text-brand-whiteSmoke">Menu</p>
            <img
              src={require("assets/close.png")}
              alt="close-icon"
              className="cursor-pointer"
              onClick={() => hideMenu()}
            />
          </div>

          {navLinks.map((navLink) => {
            return (
              <div
                className="border-b border-white py-3  text-[#8E8E8E] no-underline"
                onClick={() => hideMenu()}
              >
                <NavLink
                  to={navLink.link}
                  className={({ isActive }) => {
                    if (isActive && navLink.link !== "#") return "active";
                    return "link";
                  }}
                >
                  {navLink.name}
                </NavLink>
              </div>
            );
          })}
        </div>
      </section>
    </section>
  );
};

export default Menu;
