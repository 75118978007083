const Welcome = () => {
  return (
    <section className="mb-10 mt-28 flex flex-col">
      <div className="md:py-5 py-2 text-center w-full text-3xl md:text-7xl font-secondary font-semibold">
        Welcome To My World
      </div>
      <div className="flex overflow-hidden relative flex-col justify-start items-center px-16 py-20 mt-7 md:mt-11 w-full text-white  max-h-[350px] md:min-h-[796px]  max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src={require("assets/video-section-bg.png")}
          alt=""
          className="object-cover absolute inset-0 size-full"
        />
        <div className="flex  flex-col-reverse md:flex-row gap-5 md:gap-10 max-w-full w-[1150px] max-md:flex-wrap max-md:mt-10 bottom-0 lg:bottom-10  lg:absolute justify-center items-center z-10">
          <h2 className="flex-auto text-center self-end  text-sm text-white md:text-2xl font-medium tracking-tighter max-md:max-w-full ">
            I’m Dimitry Prophete, but many know me as Dimitry the Connector. My
            journey began in Brooklyn, New York, & took root in Miami, where I
            was raised by hardworking Haitian parents. My father, a taxi driver,
            & my mother, a CNA, instilled in me the values of hard work &
            perseverance.
          </h2>
          <button className="justify-center items-center text-nowrap px-20 py-1 md:px-16 md:py-6 text-sm md:text-3xl font-semibold text-center border border-white border-solid bg-black bg-opacity-30 rounded-[105.907px] max-md:px-5">
            Play Video
          </button>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
