/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Contact = ({ actionBtnText, bodyText }) => {
  return (
    <section className="flex justify-center items-center px-[10%] py-[3%] w-full font-semibold text-white bg-brand-darkGrey max-md:px-5 max-md:mt-5 max-md:max-w-full">
      <div className="flex flex-col mt-20 mb-10 w-full max-w-[1512px] max-md:mt-10 max-md:max-w-full">
        <h2 className="text-8xl tracking-tighter font-secondary uppercase  max-md:max-w-full max-md:text-4xl">
          Let's Connect
        </h2>
        <p className="md:mt-12 mt-4 text-xs md:text-3xl font-medium md:leading-10 leading-5 max-md:mt-10 w-full md:w-4/6 ">
          {bodyText}
        </p>
        <div className="flex gap-5 justify-between mt-8 text-sm md:text-3xl text-center max-md:flex-wrap max-md:max-w-full">
          <button className="justify-center md:px-14 p-2 md:py-5 my-auto border border-white border-solid rounded-[105.907px] max-md:px-5">
            {actionBtnText}
          </button>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6f7684d2886a2c0387f01180d077812ebd4d374075babd4f909710ffedbaef3?apiKey=e9fd01ba78ce4ebc99a1766c4ece4b68&"
            alt=""
            className="shrink-0 max-w-full mix-blend-screen aspect-[1.47] w-14 md:w-[167px]"
          />
        </div>
      </div>
    </section>
  );
};

export default Contact;
