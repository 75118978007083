/**
 * This code was generated by Builder.io.
 */
import TestimonialSwiper from "components/TestimonialSwiper";
import React from "react";

const Testimonial = () => {
  return (
    <section className="self-center w-full max-w-[1517px] px-4 md:px-10 mt-10  max-md:max-w-full h-full  md:pt-10">
      <TestimonialSwiper></TestimonialSwiper>
    </section>
  );
};

export default Testimonial;
