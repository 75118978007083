const Biography = () => {
  return (
    <section className="my-5 md:my-20 flex flex-col justify-center items-center px-4">
      <h2 className="font-medium text-xl md:text-[2vw] max-md:leading-5 w-fit md:mx-10 max-w-[1000px] text-center">
        From a young age, I was captivated by finance. Helping my family manage
        our budget sparked a passion that has driven my career.
      </h2>
      <p className="text-xs md:text-lg  font-medium mt-5 mb-5 md:mb-20 max-w-[1300px] md:w-[60vw] text-center">
        By 25, I was an accomplished college football player at the Division 1
        level, obtained my International MBA, and became the youngest member of
        the Alumni Board of Directors at Florida International University.
      </p>
      <img
        src={require("assets/about/biography-bg.png")}
        alt="Dimitry Prophete"
        className="w-full object-cover max-h-[60vh] object-top"
      />
      <p className="text-xs md:text-lg font-medium my-5 md:my-10 max-w-[1300px] max-md:leading-5 md:text-center md:w-[60vw]">
        Professionally, I have worked at the country's largest investment bank,
        Goldman Sachs, where I honed my skills in Trust, Tax, and Estate
        Planning within the internal Family Office. In the Private Wealth
        Division, I managed $3.2 billion in client assets across 55 families,
        specializing in investment management. Additionally, I have experience
        in financial consulting, capital raising, and risk management.
        <br />
        <br />
        My authentic approach and dedication have enabled me to connect deeply
        with both clients and colleagues, making finance accessible and engaging
        for everyone. I have always stayed true to myself, balancing
        professionalism with authenticity. Whether in a boardroom or connecting
        with individuals on social media, my genuine personality consistently
        shines through.
      </p>
    </section>
  );
};

export default Biography;
