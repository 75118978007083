const ServiceHero = ({ title, description }) => {
  return (
    <section
      id="hero"
      className="flex flex-col h-full overflow-hidden self-center lg:mb-16 w-full max-md:my-4 max-md:max-w-full text-white pt-[100px]"
    >
      <div className="flex h-full items-end mb-7 lg:mt-0 gap-5 justify-between max-md:flex-wrap max-md:max-w-full z-40 px-5 md:pl-[12%] md:pr-[5%]  lg:py-[4%]">
        <div className="flex  flex-col max-md:max-w-full">
          <h1 className=" leading-[0.7] md:text-[8vw] max-md:max-w-full max-md:text-4xl  max-md:leading-7 font-secondary w-[60%] font-bold">
            {title}
          </h1>
          <p className="md:mt-14 mt-5 text-lg md:text-[1.3vw] leading-normal  max-md:max-w-full max-w-[700px]">
            {description}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ServiceHero;
