const ServiceBlock = ({ svg, title, description }) => {
  return (
    <div className="px-[12%] pt-[10%] pb-[15%] flex flex-col gap-4 lg:gap-10 justify-around col-span-1 bg-[linear-gradient(223deg,#000_5.53%,#1E1A1D_49.52%)] rounded-3xl lg:min-h-[600px] lg:max-h-[600px] text-white">
      <div className="w-14 mt-4 md:mt-0  lg:w-28">{svg}</div>
      <h1 className=" w-[75%] text-2xl mb-4  lg:text-[2.4vw] font-bold leading-tight text-pretty">
        {title}
      </h1>
      <p className=" text-xs lg:text-[1.1vw] mt-2 w-5/6 text-pretty lg:leading-9 font-light">
        {description}
      </p>
    </div>
  );
};

export default ServiceBlock;
